import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import logo from "../../assets/yanggopew.png";
import {Global} from "../../helper/Global";
import {Helmet} from "react-helmet";

function Login() {
    const backend_url = process.env.BACKEND_URL;
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [alert, setAlert] = useState("");

    const handleSubmit = () => {
        fetch(Global.BACKEND_URL+ "/signin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            credentials: "include",
            body:
                JSON.stringify({
                    login: user,
                    password: password
                })
        }).then((res) => {
            if (res.status === 200) {
                window.location.href = "/Home";
            } else {
                setAlert("Invalid username or password")
            }
        }).catch((error) => {
           console.log(error)
        });
    }

    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 bg-[#282c34] text-white pt-24 md:pt-32 pb-64">
            <Helmet>
                <title>Yangfecta - Login</title>
                <meta name="description" content="Login to yangfecta" />
            </Helmet>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img className="mx-auto h-20 w-auto hidden sm:block"
                     src={logo} alt="yangfecta logo"/>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">Sign in to your
                    account</h2>
            </div>

            <p className="text-red-600">{alert}</p>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-6">
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-left">Username</label>
                        <div className="mt-2">
                            <input id="username" name="username" type="username" required
                                      onChange={(e) => setUser(e.target.value)}
                                   className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-700 placeholder-gray-400 text-white focus:ring-[#3b82f6] focus:border-[#3b82f6]"/>
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6">Password</label>
                            <div className="text-sm">
                                <a href="#" className="font-semibold text-indigo-400 hover:text-indigo-300">Forgot
                                    password?</a>
                            </div>
                        </div>
                        <div className="mt-2">
                            <input id="password" name="password" type="password"
                                   required
                                   onChange={(e) => setPassword(e.target.value)}
                                   className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-700 placeholder-gray-400 text-white focus:ring-[#3b82f6] focus:border-[#3b82f6]"/>
                        </div>
                    </div>

                    <div>
                        <button
                                onClick={handleSubmit}
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign
                            in
                        </button>
                    </div>
                </div>

                <p className="mt-10 text-center font-medium text-sm">
                    Accounts with Yangfecta are currently invite-only.<br/> You can still sign up for our newsletter <Link to="/Home" className="text-indigo-400 hover:text-indigo-300">here</Link>.
                </p>
            </div>
        </div>
    );
}

export default Login;