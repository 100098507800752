import React from "react";
import ArticleCard from "../articles/ArticleCard";
import {Global} from "../../helper/Global";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";

function Profile() {
    interface article {
        title: string;
        shortDescription: string;
        author: string;
        date: string;
        imageUrl: string;
        content: string;
        type: string;
    }

    interface authorProfile {
        username: string;
        displayName: string;
        profileImageUrl: string;
        bio: string;
    }

    const [articles, setArticles] = React.useState([] as article[]);
    let username = useParams().username;
    const [profile, setProfile] = React.useState({} as authorProfile);
    const [filteredArticles, setFilteredArticles] = React.useState([] as article[]);

    React.useEffect(() => {
        getProfileByUser();
        fetchArticles();
    }, []);

    async function getProfileByUser() {
        await fetch(Global.BACKEND_URL + "/getProfileByUser", {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
            body: JSON.stringify({
                username: username
            })
        }).then(response => {
            if (response.status !== 200) {
                window.location.href = "/Home";
            } else {
                response.json().then(data => {
                    setProfile(data);
                });
            }
        });
    }

    async function fetchArticles() {
        const response = await fetch(Global.BACKEND_URL + "/getArticles", {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include"
        });
        const data = await response.json();
        data.sort((a: article, b: article) => {
            return new Date(Date.parse(b.date)).getTime() - new Date(Date.parse(a.date)).getTime();
        });
        setArticles(data.filter((a: article) => a.author === username));
    }

    return (
        <div className="bg-[#282c34]">
            <div className="p-8">
                <Helmet>
                    <title>{"Yangfecta Profile - " + profile.displayName}</title>
                    <meta name="description" content={profile.displayName + "'s profile"}/>
                    <meta name="image" content={profile.profileImageUrl}/>
                </Helmet>
                <div className="p-8 bg-gray-800 border border-gray-700 rounded-lg shadow mt-24">
                    <div className="grid grid-cols-1 md:grid-cols-3">
                        <div className="grid grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0">
                            <div>
                                <p className="font-bold text-white text-xl">0</p>
                                <p className="text-gray-400">Likes</p>
                            </div>
                            <div>
                                <p className="font-bold text-white text-xl">{articles.filter((a) => a.author === username).length}</p>
                                <p className="text-gray-400">Articles</p>
                            </div>
                            <div>
                                <p className="font-bold text-white text-xl">0</p>
                                <p className="text-gray-400">Projects</p>
                            </div>
                        </div>
                        <div className="relative">
                            <div
                                className="w-48 h-48 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500">
                                <img
                                    className="w-48 h-48 rounded-full mx-auto shadow-lg object-cover absolute shadow-2xl flex justify-center"
                                    src={profile.profileImageUrl}
                                    alt={profile.displayName}
                                />
                            </div>
                        </div>

                        <div className="space-x-8 flex mt-32 md:mt-0 justify-center">
                            <button
                                className="text-white py-2 px-4 uppercase rounded-lg bg-indigo-600 hover:bg-indigo-500 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
                            >
                                Follow
                            </button>
                            <button
                                className="inline-flex items-center text-white py-2 px-4 uppercase rounded-lg bg-gray-700 hover:bg-gray-600 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
                            >
                                <svg height="20" width="20" viewBox="0 0 32 32"
                                     className="fill-current text-purple-400 pr-1">
                                    <path
                                        d="M22.5,4c-2,0-3.9,0.8-5.3,2.2L16,7.4l-1.1-1.1C12,3.3,7.2,3.3,4.3,6.2c0,0-0.1,0.1-0.1,0.1c-3,3-3,7.8,0,10.8L16,29	l11.8-11.9c3-3,3-7.8,0-10.8C26.4,4.8,24.5,4,22.5,4z"></path>
                                </svg>
                                Support
                            </button>
                        </div>
                    </div>

                    <div className="mt-20 text-center pb-10">
                        {profile.displayName ?  profile.displayName.length > 13 ?
                            <h1 className="text-3xl font-medium text-white sm:text-4xl">{profile.displayName} </h1> :
                                <h1 className="font-medium text-white text-4xl">{profile.displayName} </h1>
                         : <h1 className="text-white text-4xl">Loading...</h1>}
                        <p className="font-light text-gray-400 mt-3">{profile.username}</p>

                        <p className="mt-8 text-white">{profile.bio}</p>
                    </div>


                </div>

            </div>
            <div className="mx-auto max-w-screen-lg px-4 2xl:px-0 pt-10 ">
                <div className="lg:flex lg:items-center lg:justify-between lg:gap-4">
                    <h2 className="shrink-0 text-xl font-semibold text-white sm:text-2xl">Articles
                        By {profile.displayName}</h2>

                    <form className="mt-4 w-full gap-4 sm:flex sm:items-center sm:justify-end lg:mt-0">
                        <label htmlFor="simple-search" className="sr-only">Search</label>
                        <div className="relative w-full flex-1 lg:max-w-sm">
                            <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                                <svg className="h-4 w-4 text-gray-400" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="text" id="simple-search"
                                   className="block w-full rounded-lg border px-4 py-2.5 ps-9 text-sm focus:border-primary-500 focus:ring-primary-500 border-gray-600 bg-gray-700 text-white placeholder:text-gray-400 focus:border-primary-500 focus:ring-primary-500"
                                   placeholder="Search Articles & Tutorials"
                                   onChange={(e) => {
                                       // filter articles after index 1 based on search input
                                       const search = e.target.value.toLowerCase();
                                       const filtered = articles.filter((article) => article.title.toLowerCase().includes(search) || article.shortDescription.toLowerCase().includes(search));
                                       if (search === "") {
                                           setFilteredArticles([]);
                                       } else {
                                           setFilteredArticles(filtered);
                                       }
                                   }}
                            />
                        </div>
                    </form>
                </div>

                <div className="mt-6 flow-root text-left">
                    <div className="-my-6 grid gap-4 pt-6">
                        {filteredArticles.length === 0 ? articles.map((article, index) =>
                            <ArticleCard title={article.title} shortDescription={article.shortDescription}
                                         author={profile.displayName}
                                         authorUser={article.author}
                                         date={article.date}
                                         imageurl={article.imageUrl}
                                         content={article.content} type={article.type}/>
                        ) : filteredArticles.map((article, index) => (
                            <ArticleCard title={article.title} shortDescription={article.shortDescription}
                                         author={profile.displayName}
                                         authorUser={article.author}
                                         date={article.date}
                                         imageurl={article.imageUrl}
                                         content={article.content} type={article.type}/>
                        ))}

                    </div>
                </div>

                <div className="mt-6 flex items-center justify-center p-6">
                    <button type="button"
                            className="w-full rounded-lg px-5 py-2.5 text-sm font-medium hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 border-gray-600 bg-gray-800 text-gray-400 hover:bg-gray-700 hover:text-white focus:ring-gray-700 sm:w-auto">View
                        more articles
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Profile;