import React from "react";

function dateToDaysAgo(date: string) {
    let newDate = date.split("-");
    const date1 = new Date(parseInt(newDate[2]), parseInt(newDate[0]) - 1, parseInt(newDate[1]));
    const date2 = new Date();
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 6) {
        return date;
    }
    return diffDays + " days ago";
}

function FeatureCard({title, shortDescription, author, authorUser, authorPfp, date, imageurl, content, type}: {
    title: string,
    shortDescription: string,
    author: string,
    authorUser: string,
    authorPfp: string,
    date: string,
    imageurl: string,
    content: string,
    type: string
}) {
    // TODO style change so the cards dont look wack with different heights and stuff
    return (
        <article className="p-6 rounded-lg grid grid-cols-1 border shadow-md bg-gray-800 border-gray-700">
            <div className="flex justify-between items-center mb-5 text-gray-500">
                {type === "Tutorial" ? (
                    <span
                        className="bg-[#dbeafe] text-[#1e40af] text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
                        <svg className="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg"><path
                            d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path></svg>
                        Tutorial
                    </span>
                ) : (
                    <span
                        className="bg-[#dbeafe] text-[#1e40af] text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
                        <svg className="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd"
                                                                      d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                                                                      clip-rule="evenodd"></path><path
                            d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"></path></svg>
                        Article
                    </span>
                )}
                <span className="text-sm">{dateToDaysAgo(date)}</span>
            </div>
            <h2 className="mb-2 text-2xl font-bold tracking-tight text-white"><a href={'/article/' + title}>{title}</a></h2>
            <p className="mb-5 font-light text-gray-400">{shortDescription}</p>
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                    <img className="w-7 h-7 rounded-full"
                         src={authorPfp} alt="author"
                    />
                    <a className="font-medium text-white hover:underline" href={"/profile/" + authorUser}>
                        {author}
                    </a>
                </div>
                <a href={'/article/' + title} className="inline-flex items-center font-medium text-[#2563eb] hover:underline">
                    Read more
                    <svg className="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                              clip-rule="evenodd"></path>
                    </svg>
                </a>
            </div>
        </article>
    );
}

export default FeatureCard;