import React, {useEffect, useState} from "react";
import CommentCard from "./CommentCard";
import {Global} from "../../helper/Global";

const mockcommentdata= [
    {
        author: "Glowstick",
        comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        date: "Feb. 8, 2022"
    },
    {
        author: "Cola",
        comment: "Netus et malesuada fames ac turpis egestas maecenas pharetra. Ac auctor augue mauris augue neque gravida in fermentum et. Interdum velit laoreet id donec ultrices.",
        date: "Feb. 12, 2022"
    },
    {
        author: "Post This Cat",
        comment: "Interdum velit laoreet id donec ultrices. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        date: "Feb. 18, 2022"
    }
]

function PreviewArticle() {

    interface Article {
        title: string;
        author: string;
        date: string;
        shortDescription: string;
        imageUrl: string;
        content: string;
        type: string;
    }

    const [article, setArticle] = useState({} as Article);

    useEffect(() => {
        if (sessionStorage.getItem("article") === null) {
            window.location.href = "/articles";
        }
        setArticle(JSON.parse(sessionStorage.getItem("article") || "{}"));
    }, []);

    function createPost() {
        fetch(Global.BACKEND_URL + "/createArticle", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
            body: JSON.stringify({
                title: article.title,
                shortDescription: article.shortDescription,
                author: article.author,
                date: article.date,
                imageUrl: article.imageUrl,
                content: article.content,
                type: article.type,
            })
        }).then(response => {
            console.log(response);
            if (response.status === 200) {
                window.location.href = "/article/" + article.title;
                sessionStorage.removeItem("article");
            } else {
                alert("Failed to post article")
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <main className="pt-8 pb-16 lg:pt-8 lg:pb-24 bg-[#282c34] antialiased text-left">
            <section
                className="fixed max-w-md p-4 mx-auto border bg-gray-800 left-6 right-6 bottom-16 border-gray-700 rounded-2xl"
                id="cookieNotice">
                <h2 className="font-semibold text-white text-center">Article Preview</h2>

                <div className="items-center grid grid-cols-2 mt-4 gap-x-4 shrink-0">
                    <button
                        className="text-xs bg-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
                        onClick={() => {
                            window.location.href = "/createarticle";
                        }}>
                        Back
                    </button>
                    <button
                        className=" text-xs bg-indigo-600 hover:bg-indigo-500 font-medium rounded-lg text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
                        onClick={() => {
                            createPost();
                        }}>
                        Post
                    </button>
                </div>
            </section>

            <div className="flex justify-between px-4 mx-auto max-w-screen-xl text-gray-300">
                <article className="mx-auto w-full max-w-4xl format format-sm sm:format-base lg:format-lg">
                    <header className="mb-4 lg:mb-6 not-format">
                        {article.imageUrl !== "#" && article.imageUrl !== "" ? (
                            <img src={article.imageUrl} alt={article.title} className="w-full h-46 sm:h-96 object-cover rounded-lg mb-5"/>
                        ) : null}
                        <h1 className="mb-4 text-3xl font-extrabold leading-tight lg:mb-6 lg:text-4xl text-white">{article.title}</h1>
                        <address className="flex items-center mb-6 not-italic">
                            <div className="inline-flex items-center mr-3 text-sm text-white">
                                <img className="mr-4 w-16 h-16 rounded-full" src={"https://media.istockphoto.com/id/1130884625/vector/user-member-vector-icon-for-ui-user-interface-or-profile-face-avatar-app-in-circle-design.jpg?s=612x612&w=0&k=20&c=1ky-gNHiS2iyLsUPQkxAtPBWH1BZt0PKBB1WBtxQJRE="} alt={article.author}/>
                                <div>
                                    <a href={"/profile/" + article.author} rel="author" className="text-xl font-bold text-white">{article.author}</a>
                                    <p className="text-base text-gray-400">{"Yangfecta Author"}</p>
                                    <p className="text-base text-gray-400">{article.date}</p>
                                </div>
                            </div>
                        </address>
                    </header>

                    {/* TODO style other prose elements like highlight, strikethrough, and more if they look wack here */}
                    <p dangerouslySetInnerHTML={{ __html: article.content }} className="prose prose-lg max-w-full text-gray-300 prose-headings:text-white prose-strong:text-white prose-li:marker:text-white prose-ol:marker:text-white prose-a:text-indigo-400 prose-a:no-underline"></p>

                    <section className="not-format pt-10">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-lg lg:text-2xl font-bold text-white">Discussion ({mockcommentdata.length})</h2>
                        </div>
                        <form className="mb-6">
                            <div className="py-2 px-4 mb-4 rounded-lg rounded-t-lg border bg-gray-800 border-gray-700">
                                <label htmlFor="comment" className="sr-only">Your comment</label>
                                <textarea id="comment" rows={6}
                                          className="px-0 w-full text-sm border-0 focus:ring-0 text-white placeholder-gray-400 bg-gray-800 focus:outline-none"
                                          placeholder="Write a comment..." required></textarea>
                            </div>
                            <button type="submit"
                                    className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-[#1d4ed8] rounded-lg focus:ring-4 focus:ring-[#1e3a8a] hover:bg-[#1e40af]">
                                Post comment
                            </button>
                        </form>

                        {mockcommentdata.map((comment, index) => (
                            // render first 2 articles as FeatureCard
                            <CommentCard
                                key={index}
                                author={comment.author}
                                comment={comment.comment}
                                date={comment.date}
                            />
                        ))}
                    </section>
                </article>
            </div>
        </main>
    );
}

export default PreviewArticle;