import React from 'react';
import './App.css';
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Cookie from "./components/notices/Cookies";
import {Outlet} from "react-router-dom";
import SEO from "./components/SEO/SEO";

function App() {

    return (
        <div>
            <div className="App">
                <Navbar/>
                <div className="routes">
                    <Outlet/>
                </div>
                <Cookie/>
                <Footer/>
            </div>
        </div>
    );
}

export default App;
