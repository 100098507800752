import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";
import React from "react";

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

function CommentCard({author, comment, date}: {author: string, comment: string, date: string}) {
  return (
      <article className="p-6 mb-6 text-base rounded-lg bg-gray-900">
          <footer className="flex justify-between items-center mb-2">
              <div className="flex items-center">
                  <p className="inline-flex items-center mr-3 font-semibold text-sm text-white"><img
                      className="mr-2 w-6 h-6 rounded-full"
                      src="https://media.istockphoto.com/id/1130884625/vector/user-member-vector-icon-for-ui-user-interface-or-profile-face-avatar-app-in-circle-design.jpg?s=612x612&w=0&k=20&c=1ky-gNHiS2iyLsUPQkxAtPBWH1BZt0PKBB1WBtxQJRE="
                      alt={author}/>{author}</p>
                  <p className="text-sm text-gray-400">{date}</p>
              </div>
              <Menu as="div" className="relative ml-3">
                  <div>
                      <MenuButton
                          className="relative text-sm focus:outline-none focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5"/>
                          <span className="sr-only">Open Comment Controls</span>
                          <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                              <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
                          </svg>
                      </MenuButton>
                  </div>
                  <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                      <MenuItem>
                          {({focus}) => (
                              <a
                                  href="#"
                                  className={classNames(focus ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                  Edit
                              </a>
                          )}
                      </MenuItem>
                      <MenuItem>
                          {({focus}) => (
                              <a
                                  href="#"
                                  className={classNames(focus ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                  Remove
                              </a>
                          )}
                      </MenuItem>
                      <MenuItem>
                          {({focus}) => (
                              <a
                                  href="#"
                                  className={classNames(focus ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                  Report
                              </a>
                          )}
                      </MenuItem>
                  </MenuItems>
              </Menu>
          </footer>
          <p>{comment}</p>
          <div className="flex items-center mt-4 space-x-4">
              <button type="button"
                      className="flex items-center font-medium text-sm hover:underline text-gray-400">
                  <svg className="mr-1.5 w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                      <path d="M18 0H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h2v4a1 1 0 0 0 1.707.707L10.414 13H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5 4h2a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2ZM5 4h5a1 1 0 1 1 0 2H5a1 1 0 0 1 0-2Zm2 5H5a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Zm9 0h-6a1 1 0 0 1 0-2h6a1 1 0 1 1 0 2Z"/>
                  </svg>
                  Reply
              </button>
          </div>
      </article>
  );
}

export default CommentCard;