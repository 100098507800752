import logo from "../../assets/yanggopew.png";
import React, {useEffect} from "react";
import {Helmet} from "react-helmet";

function Projects() {

    return (
        <header className="App-header">
            <Helmet>
                <title>Yangfecta - Projects</title>
                <meta name="description" content="Yangfecta project portfolio" />
            </Helmet>
            <img src={logo} className="App-logo h-[40vmin]" alt="logo" />
            <p>
                Projects
            </p>
        </header>
    )
}

export default Projects;