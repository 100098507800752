import React from "react";

function dateToDaysAgo(date: string) {
    let newDate = date.split("-");
    const date1 = new Date(parseInt(newDate[2]), parseInt(newDate[0]) - 1, parseInt(newDate[1]));
    const date2 = new Date();
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 6) {
        return date;
    }
    return diffDays + " days ago";
}

function ArticleCard({title, shortDescription, authorUser, author, date, imageurl, content, type}: {
    title: string,
    shortDescription: string,
    authorUser: string,
    author: string,
    date: string,
    imageurl: string,
    content: string,
    type: string
}) {
    return (
        <div className="space-y-4 py-6 md:py-8 rounded-lg shadow-md bg-gray-800 p-5">
            <div className="grid gap-4">
                <div>
                    {type === "Tutorial" ? (
                    <span
                        className="bg-[#dbeafe] text-[#1e40af] text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
                        <svg className="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg"><path
                            d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path></svg>
                        Tutorial
                    </span>
                    ) : (
                        <span
                            className="bg-[#dbeafe] text-[#1e40af] text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
                        <svg className="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd"
                                                                      d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                                                                      clip-rule="evenodd"></path><path
                            d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"></path></svg>
                            Article
                    </span>
                        )}
                </div>

                <a href={'/article/' + title}
                   className="text-xl font-semibold hover:underline text-white">{title}</a>
            </div>
            <p className="text-base font-normal text-gray-400">{shortDescription}</p>
            <p className="text-sm font-medium text-gray-400">
                {dateToDaysAgo(date)} by
                <a href={"/profile/" + authorUser} className="hover:underline text-white ml-1">{author}</a>
            </p>
        </div>
    );
}

export default ArticleCard;