import React, {useEffect} from "react";
import {Global} from "../../helper/Global";

function Cards({displayName, username, imageUrl, bio}: { displayName: string, username: string, imageUrl: string, bio: string}) {
    interface article {
        author: string;
    }

    const [articles, setArticles] = React.useState([] as article[]);

    useEffect(() => {
        getArticles();
    }, []);

    async function getArticles() {
        fetch(Global.BACKEND_URL + "/getArticles", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
        }).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setArticles(data);
                });
            }
        })
    }

  return (
      <div className="w-full border rounded-lg shadow bg-gray-800 border-gray-700">
          <div className="flex flex-col items-center py-12">
              <img className="w-32 h-32 mb-3 rounded-full shadow-lg object-cover" src={imageUrl}/>
              <h5 className="mb-1 text-xl font-medium text-white">{displayName}</h5>
              <span className="text-sm text-gray-400">{bio}</span>
              <div className="flex mt-4 md:mt-6 gap-2">
                  <a href={"/profile/" + username} className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">Profile</a>
                  <a href="#" className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-purple-400 rounded-lg focus:ring-4 focus:outline-none border border-purple-500 hover:border-purple-500 focus:ring-blue-800"><svg height="20" width="20" viewBox="0 0 32 32" className="fill-current text-purple-400 pr-1"><path d="M22.5,4c-2,0-3.9,0.8-5.3,2.2L16,7.4l-1.1-1.1C12,3.3,7.2,3.3,4.3,6.2c0,0-0.1,0.1-0.1,0.1c-3,3-3,7.8,0,10.8L16,29	l11.8-11.9c3-3,3-7.8,0-10.8C26.4,4.8,24.5,4,22.5,4z"></path></svg>Sponsor</a>
              </div>
              <span className="font-black pt-5">0 Likes | {articles.filter((a) => a.author === username).length} Articles | 0 Projects</span>
          </div>
      </div>

  );
}

export default Cards;