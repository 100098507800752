import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom';
import Home from "./pages/home/Home";
import Articles from "./pages/articles/Articles";
import Article from "./pages/articles/Article";
import Projects from "./pages/projects/Projects";
import Authors from "./pages/authors/Authors";
import Login from "./pages/login/Login";
import CreateArticle from "./pages/articles/CreateArticle";
import Register from "./pages/register/Register";
import Settings from "./pages/profile/Settings";
import Profile from "./pages/profile/Profile";
import PreviewArticle from "./pages/articles/PreviewArticle";
import TOS from "./pages/legal/TOS";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import CookiePolicy from "./pages/legal/CookiePolicy";

const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        children: [
            {
                path: '/',
                element: <Navigate to="/home" replace={true}/>,
            },
            {
                path: 'home',
                element: <Home/>,
            },
            {
                path: 'articles',
                element: <Articles/>,
            },
            {
                path: 'article/:title',
                element: <Article/>,
            },
            {
                path: 'createarticle',
                element: <CreateArticle/>,
            },
            {
                path: 'projects',
                element: <Projects/>,
            },
            {
                path: 'authors',
                element: <Authors/>,
            },
            {
                path: 'register/:uniqueId',
                element: <Register/>,
            },
            {
                path: 'login',
                element: <Login/>,
            },
            {
                path: 'settings',
                element: <Settings/>,
            },
            {
                path: 'profile/:username',
                element: <Profile/>,
            },
            {
                path: 'preview',
                element: <PreviewArticle/>,
            },
            {
                path: 'TOS',
                element: <TOS/>,
            },
            {
                path: 'privacypolicy',
                element: <PrivacyPolicy/>,
            },
            {
                path: 'cookiepolicy',
                element: <CookiePolicy/>,
            }
        ],
    },
],);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
