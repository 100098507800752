function PrivacyPolicy() {
    return (
        <div className="App-header">
            <h1 className="mt-10 mb-2 font-black">Privacy Policy</h1>
            <p className="text-sm mb-10">Last Updated: 7/12/2024</p>
            <div>
                <div
                    className="text-left grid grid-cols-1 gap-5 font-serif bg-gray-800 mx-16 p-5 mb-10 rounded-lg prose-a:text-indigo-400">
                    <div><span
                        data-custom-class="body_text">This privacy notice for&nbsp;__________&nbsp;("<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"</span><span
                        data-custom-class="body_text">), describes how and why we might collect, store, use, and/or share ("<strong>process</strong>") your information when you use our services ("<strong>Services</strong>"), such as when you:</span>
                    </div>
                    <ul>
                        <li data-custom-class="body_text"><span data-custom-class="body_text">Visit our website at&nbsp;
                            <a href="https://www.yangfecta.com/" data-custom-class="link">https://www.yangfecta.com/</a>, or any website of ours that links to this privacy notice</span>
                        </li>
                    </ul>
                    <div className="grid grid-cols-1 gap-5">
                        <ul>
                            <li data-custom-class="body_text"><span data-custom-class="body_text">Engage with us in other related ways, including any sales, marketing, or events</span>
                            </li>
                        </ul>
                        <div><span data-custom-class="body_text"><strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.</span>
                        </div>
                        <div><strong><span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span></strong></div>
                        <div><span data-custom-class="body_text"><strong><em>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our&nbsp;</em></strong></span><a
                            href="#toc" data-custom-class="link"><span data-custom-class="body_text"><strong><em>table of contents</em></strong></span></a><span
                            data-custom-class="body_text"><strong><em>&nbsp;below to find the section you are looking for.</em></strong></span>
                        </div>
                        <div><span
                            data-custom-class="body_text"><strong>What personal information do we process?</strong>&nbsp;When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about&nbsp;</span><a
                            href="#personalinfo" data-custom-class="link"><span data-custom-class="body_text">personal information you disclose to us</span></a><span
                            data-custom-class="body_text">.</span></div>
                        <div><span data-custom-class="body_text"><strong>Do we process any sensitive personal information?</strong>&nbsp;We do not process sensitive personal information.</span>
                        </div>
                        <div><span data-custom-class="body_text"><strong>Do we collect any information from third parties?</strong>&nbsp;We do not collect any information from third parties.</span>
                        </div>
                        <div><span
                            data-custom-class="body_text"><strong>How do we process your information?</strong>&nbsp;We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about&nbsp;</span><a
                            href="#infouse" data-custom-class="link"><span data-custom-class="body_text">how we process your information</span></a><span
                            data-custom-class="body_text">.</span></div>
                        <div><span data-custom-class="body_text"><strong>In what situations and with which&nbsp;parties do we share personal information?</strong>&nbsp;We may share information in specific situations and with specific third parties. Learn more about&nbsp;</span><a
                            href="#whoshare" data-custom-class="link"><span data-custom-class="body_text">when and with whom we share your personal information</span></a><span
                            data-custom-class="body_text">.</span></div>
                        <div><span
                            data-custom-class="body_text"><strong>How do we keep your information safe?</strong>&nbsp;We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about&nbsp;</span><a
                            href="#infosafe" data-custom-class="link"><span data-custom-class="body_text">how we keep your information safe</span></a><span
                            data-custom-class="body_text">.</span></div>
                        <div><span data-custom-class="body_text"><strong>What are your rights?</strong>&nbsp;Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about&nbsp;</span><a
                            href="#privacyrights" data-custom-class="link"><span data-custom-class="body_text">your privacy rights</span></a><span
                            data-custom-class="body_text">.</span></div>
                        <div><span
                            data-custom-class="body_text"><strong>How do you exercise your rights?</strong>&nbsp;The easiest way to exercise your rights is by submitting a&nbsp;</span><a
                            href="#" rel="noopener noreferrer" data-custom-class="link"><span
                            data-custom-class="body_text">data subject access request</span></a><span
                            data-custom-class="body_text">, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</span>
                        </div>
                        <div><span data-custom-class="body_text">Want to learn more about what we do with any information we collect?&nbsp;</span><a
                            href="#toc" data-custom-class="link"><span data-custom-class="body_text">Review the privacy notice in full</span></a><span
                            data-custom-class="body_text">.</span></div>
                        <div><strong><span data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></div>
                        <div><a href="#infocollect" data-custom-class="link">1. WHAT INFORMATION DO WE COLLECT?</a>
                        </div>
                        <div><a href="#infouse" data-custom-class="link">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
                        </div>
                        <div><a href="#whoshare" data-custom-class="link">3. WHEN AND WITH WHOM DO WE SHARE YOUR
                            PERSONAL INFORMATION?</a></div>
                        <div><a href="#cookies" data-custom-class="link">4. DO WE USE COOKIES AND OTHER TRACKING
                            TECHNOLOGIES?</a></div>
                        <div><a href="#inforetain" data-custom-class="link">5. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
                        </div>
                        <div><a href="#infosafe" data-custom-class="link">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
                        </div>
                        <div><a href="#infominors" data-custom-class="link">7. DO WE COLLECT INFORMATION FROM
                            MINORS?</a></div>
                        <div><a href="#privacyrights" data-custom-class="link">8. WHAT ARE YOUR PRIVACY RIGHTS?</a>
                        </div>
                        <div><a href="#DNT" data-custom-class="link">9. CONTROLS FOR DO-NOT-TRACK FEATURES</a></div>
                        <div><a href="#uslaws" data-custom-class="link">10. DO UNITED STATES RESIDENTS HAVE SPECIFIC
                            PRIVACY RIGHTS?</a></div>
                        <div><a href="#policyupdates" data-custom-class="link">11. DO WE MAKE UPDATES TO THIS
                            NOTICE?</a></div>
                        <div><a href="#contact" data-custom-class="link">12. HOW CAN YOU CONTACT US ABOUT THIS
                            NOTICE?</a></div>
                        <div><a href="#request" data-custom-class="link">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE
                            DATA WE COLLECT FROM YOU?</a></div>
                        <div><strong><span
                            data-custom-class="heading_1" id="infocollect">1. WHAT INFORMATION DO WE COLLECT?</span></strong></div>
                        <div><span
                            data-custom-class="heading_2"><strong>Personal information you disclose to us</strong></span>
                        </div>
                        <div>
                            <div><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span><span
                                data-custom-class="body_text"><em>We collect personal information that you provide to us.</em></span>
                            </div>
                        </div>
                        <div><span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when you register on the Services,&nbsp;</span><span
                            data-custom-class="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span>
                        </div>
                        <div><span
                            data-custom-class="body_text"><strong>Personal Information Provided by You.</strong>&nbsp;The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</span>
                        </div>
                        <ul>
                            <li data-custom-class="body_text"><span data-custom-class="body_text">email addresses</span>
                            </li>
                        </ul>
                        <ul>
                            <li data-custom-class="body_text"><span data-custom-class="body_text">usernames</span></li>
                        </ul>
                        <ul>
                            <li data-custom-class="body_text"><span data-custom-class="body_text">passwords</span></li>
                        </ul>
                        <ul>
                            <li data-custom-class="body_text"><span
                                data-custom-class="body_text">contact preferences</span></li>
                        </ul>
                        <ul>
                            <li data-custom-class="body_text"><span data-custom-class="body_text">contact or authentication data</span>
                            </li>
                        </ul>
                        <div><span data-custom-class="body_text"><strong>Sensitive Information.</strong>&nbsp;We do not process sensitive information.</span>
                        </div>
                        <div><span data-custom-class="body_text">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span>
                        </div>
                        <div><strong><span
                            data-custom-class="heading_1" id="infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</span></strong></div>
                        <div>
                            <div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></span>
                            </div>
                        </div>
                        <div><span data-custom-class="body_text"><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong></span>
                        </div>
                        <ul>
                            <li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;</strong>We may process your information so you can create and log in to your account, as well as keep your account in working order.</span>
                            </li>
                        </ul>
                        <ul>
                            <li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To deliver and facilitate delivery of services to the user.&nbsp;</strong>We may process your information to provide you with the requested service.</span>
                            </li>
                        </ul>
                        <div>
                            <div>
                                <div>
                                    <ul>
                                        <li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To send administrative information to you.&nbsp;</strong>We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</span>
                                        </li>
                                    </ul>
                                    <div>
                                        <div>
                                            <div>
                                                <ul>
                                                    <li data-custom-class="body_text"><span
                                                        data-custom-class="body_text"><strong>To enable user-to-user communications.&nbsp;</strong>We may process your information if you choose to use any of our offerings that allow for communication with another user.</span>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li data-custom-class="body_text"><span
                                                        data-custom-class="body_text"><strong>To send you marketing and promotional communications.&nbsp;</strong>We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see&nbsp;"</span><a
                                                        href="#privacyrights"><span data-custom-class="link">WHAT ARE YOUR PRIVACY RIGHTS?</span></a><span
                                                        data-custom-class="body_text">"&nbsp;below.</span></li>
                                                </ul>
                                                <div>
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <ul>
                                                                    <li data-custom-class="body_text"><span
                                                                        data-custom-class="body_text"><strong>To protect our Services.</strong>&nbsp;We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</span>
                                                                    </li>
                                                                </ul>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <div>
                                                                                    <div>
                                                                                        <div>
                                                                                            <ul>
                                                                                                <li data-custom-class="body_text">
                                                                                                    <span
                                                                                                        data-custom-class="body_text"><strong>To comply with our legal obligations.</strong>&nbsp;We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.</span>
                                                                                                </li>
                                                                                            </ul>
                                                                                            <div>
                                                                                                <div><strong><span
                                                                                                    data-custom-class="heading_1" id="whoshare">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong>
                                                                                                </div>
                                                                                                <div><span
                                                                                                    data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may share information in specific situations described in this section and/or with the following third parties.</em></span>
                                                                                                </div>
                                                                                                <div><span
                                                                                                    data-custom-class="body_text">We&nbsp;may need to share your personal information in the following situations:</span>
                                                                                                </div>
                                                                                                <ul>
                                                                                                    <li data-custom-class="body_text">
                                                                                                        <span
                                                                                                            data-custom-class="body_text"><strong>Business Transfers.</strong>&nbsp;We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <div>
                                                                                                    <div>
                                                                                                        <div>
                                                                                                            <div>
                                                                                                                <div>
                                                                                                                    <div>
                                                                                                                        <strong><span
                                                                                                                            data-custom-class="heading_1" id="cookies">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></strong>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</em></span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services&nbsp;and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">To the extent these online tracking technologies are deemed to be a&nbsp;"sale"/"sharing"&nbsp;(which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section&nbsp;"</span><span
                                                                                                                        data-custom-class="body_text"><a
                                                                                                                        href="#uslaws"
                                                                                                                        data-custom-class="link">DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></span><span
                                                                                                                        data-custom-class="body_text">"</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <strong><span
                                                                                                                            data-custom-class="heading_1" id="inforetain">5. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We keep your information for as long as necessary to&nbsp;fulfill&nbsp;the purposes outlined in this privacy notice unless otherwise required by law.</em></span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).&nbsp;No purpose in this notice will require us keeping your personal information for longer than&nbsp;the period of time in which users have an account with us.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or&nbsp;anonymize&nbsp;such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <strong><span
                                                                                                                            data-custom-class="heading_1" id="infosafe">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We aim to protect your personal information through a system of&nbsp;organizational&nbsp;and technical security measures.</em></span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">We have implemented appropriate and reasonable technical and&nbsp;organizational&nbsp;security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other&nbsp;unauthorized&nbsp;third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <strong><span
                                                                                                                            data-custom-class="heading_1" id="infominors">7. DO WE COLLECT INFORMATION FROM MINORS?</span></strong>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We do not knowingly collect data from or market to children under 18 years of age.</em></span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at&nbsp;__________.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <strong><span
                                                                                                                            data-custom-class="heading_1" id="privacyrights">8. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text"><strong><em>In Short:</em></strong><em> You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.</em></span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text"><strong><u>Withdrawing your consent:</u></strong>&nbsp;If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "</span><a
                                                                                                                        href="#contact"
                                                                                                                        data-custom-class="link"><span
                                                                                                                        data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a><span
                                                                                                                        data-custom-class="body_text">"&nbsp;below.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,&nbsp;when applicable law allows,&nbsp;will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text"><strong><u>Opting out of marketing and promotional communications:</u></strong>You can unsubscribe from our marketing and promotional communications at any time by&nbsp;clicking on the unsubscribe link in the emails that we send,&nbsp;or by contacting us using the details provided in the section&nbsp;"</span><a
                                                                                                                        href="#contact"
                                                                                                                        data-custom-class="link"><span
                                                                                                                        data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a><span
                                                                                                                        data-custom-class="body_text">"&nbsp;below. You will then be removed from the marketing lists. However, we may still communicate with you &mdash; for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="heading_2"><strong>Account Information</strong></span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">If you would at any time like to review or change the information in your account or terminate your account, you can:</span>
                                                                                                                    </div>
                                                                                                                    <ul>
                                                                                                                        <li data-custom-class="body_text">
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">Log in to your account settings and update your user account.</span>
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text"><strong><u>Cookies and similar technologies:</u></strong>&nbsp;Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <strong><span
                                                                                                                            data-custom-class="heading_1" id="DNT">9. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for&nbsp;recognizing&nbsp;and implementing DNT signals has been&nbsp;finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for&nbsp;recognizing&nbsp;or&nbsp;honoring&nbsp;DNT signals, we do not respond to them at this time.</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <strong><span
                                                                                                                            data-custom-class="heading_1" id="uslaws">10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>If you are a resident of, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.</em></span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <strong><span
                                                                                                                            data-custom-class="heading_2">Categories of Personal Information We Collect</span></strong>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">We have collected the following categories of personal information in the past twelve (12) months:</span>
                                                                                                                    </div>
                                                                                                                    <table>
                                                                                                                        <tbody>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <span
                                                                                                                                    data-custom-class="body_text"><strong>Category</strong></span>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <span
                                                                                                                                    data-custom-class="body_text"><strong>Examples</strong></span>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <span
                                                                                                                                    data-custom-class="body_text"><strong>Collecte</strong></span>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">A. Identifiers</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">NO</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                    <table>
                                                                                                                        <tbody>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">B. Protected classification characteristics under state or federal law</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div
                                                                                                                                    data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">NO</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">C. Commercial information</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">Transaction information, purchase history, financial details, and payment information</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div
                                                                                                                                    data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">NO</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">D. Biometric information</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">Fingerprints and voiceprints</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div
                                                                                                                                    data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">NO</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">E. Internet or other similar network activity</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">Browsing history, search history, online&nbsp;behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div
                                                                                                                                    data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">NO</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">F. Geolocation data</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">Device location</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div
                                                                                                                                    data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">NO</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">G. Audio, electronic, sensory, or similar information</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">Images and audio, video or call recordings created in connection with our business activities</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div
                                                                                                                                    data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">NO</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">H. Professional or employment-related information</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div
                                                                                                                                    data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">NO</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">I. Education Information</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">Student records and directory information</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div
                                                                                                                                    data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">NO</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">J. Inferences drawn from collected personal information</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual&rsquo;s preferences and characteristics</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div
                                                                                                                                    data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        data-custom-class="body_text">NO</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <span
                                                                                                                                    data-custom-class="body_text">K. Sensitive personal Information</span>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <div
                                                                                                                                    data-custom-class="body_text"
                                                                                                                                    data-empty="true">NO
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                    <div>
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</span>
                                                                                                                    </div>
                                                                                                                    <ul>
                                                                                                                        <li data-custom-class="body_text">Receiving
                                                                                                                            help
                                                                                                                            through
                                                                                                                            our
                                                                                                                            customer
                                                                                                                            support
                                                                                                                            channels;
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                    <ul>
                                                                                                                        <li data-custom-class="body_text">Participation
                                                                                                                            in
                                                                                                                            customer
                                                                                                                            surveys
                                                                                                                            or
                                                                                                                            contests;
                                                                                                                            and
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                    <ul>
                                                                                                                        <li data-custom-class="body_text">Facilitation
                                                                                                                            in
                                                                                                                            the
                                                                                                                            delivery
                                                                                                                            of
                                                                                                                            our
                                                                                                                            Services
                                                                                                                            and
                                                                                                                            to
                                                                                                                            respond
                                                                                                                            to
                                                                                                                            your
                                                                                                                            inquiries.
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                    <div>
                                                                                                                        <div>
                                                                                                                            <strong><span
                                                                                                                                data-custom-class="heading_2">Sources of Personal Information</span></strong>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">Learn more about the sources of personal information we collect in&nbsp;"</span><span
                                                                                                                            data-custom-class="body_text"><a
                                                                                                                            href="#infocollect"
                                                                                                                            data-custom-class="link">WHAT INFORMATION DO WE COLLECT?</a></span><span
                                                                                                                            data-custom-class="body_text">"</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <strong><span
                                                                                                                                data-custom-class="heading_2">How We Use and Share Personal Information</span></strong>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">Learn about how we use your personal information in the section,&nbsp;"</span><a
                                                                                                                            href="#infouse"
                                                                                                                            data-custom-class="link">HOW
                                                                                                                            DO
                                                                                                                            WE
                                                                                                                            PROCESS
                                                                                                                            YOUR
                                                                                                                            INFORMATION?</a><span
                                                                                                                            data-custom-class="body_text">"</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text"><strong>Will your information be shared with anyone else?</strong></span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section,&nbsp;"</span><a
                                                                                                                            href="#whoshare"
                                                                                                                            data-custom-class="link">WHEN
                                                                                                                            AND
                                                                                                                            WITH
                                                                                                                            WHOM
                                                                                                                            DO
                                                                                                                            WE
                                                                                                                            SHARE
                                                                                                                            YOUR
                                                                                                                            PERSONAL
                                                                                                                            INFORMATION?</a><span
                                                                                                                            data-custom-class="body_text">"</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be&nbsp;"selling"&nbsp;of your personal information.</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We&nbsp;will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <strong><span
                                                                                                                                data-custom-class="heading_2">Your Rights</span></strong>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:</span>
                                                                                                                        </div>
                                                                                                                        <ul>
                                                                                                                            <li data-custom-class="body_text">
                                                                                                                                <strong>Right
                                                                                                                                    to
                                                                                                                                    know</strong>&nbsp;whether
                                                                                                                                or
                                                                                                                                not
                                                                                                                                we
                                                                                                                                are
                                                                                                                                processing
                                                                                                                                your
                                                                                                                                personal
                                                                                                                                data
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <ul>
                                                                                                                            <li data-custom-class="body_text">
                                                                                                                                <strong>Right
                                                                                                                                    to
                                                                                                                                    access&nbsp;</strong>your
                                                                                                                                personal
                                                                                                                                data
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <ul>
                                                                                                                            <li data-custom-class="body_text">
                                                                                                                                <strong>Right
                                                                                                                                    to
                                                                                                                                    correct&nbsp;</strong>inaccuracies
                                                                                                                                in
                                                                                                                                your
                                                                                                                                personal
                                                                                                                                data
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <ul>
                                                                                                                            <li data-custom-class="body_text">
                                                                                                                                <strong>Right
                                                                                                                                    to
                                                                                                                                    request</strong>&nbsp;the
                                                                                                                                deletion
                                                                                                                                of
                                                                                                                                your
                                                                                                                                personal
                                                                                                                                data
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <ul>
                                                                                                                            <li data-custom-class="body_text">
                                                                                                                                <strong>Right
                                                                                                                                    to
                                                                                                                                    obtain
                                                                                                                                    a
                                                                                                                                    copy&nbsp;</strong>of
                                                                                                                                the
                                                                                                                                personal
                                                                                                                                data
                                                                                                                                you
                                                                                                                                previously
                                                                                                                                shared
                                                                                                                                with
                                                                                                                                us
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <ul>
                                                                                                                            <li data-custom-class="body_text">
                                                                                                                                <strong>Right
                                                                                                                                    to
                                                                                                                                    non-discrimination</strong>&nbsp;for
                                                                                                                                exercising
                                                                                                                                your
                                                                                                                                rights
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <ul>
                                                                                                                            <li data-custom-class="body_text">
                                                                                                                                <strong>Right
                                                                                                                                    to
                                                                                                                                    opt
                                                                                                                                    out</strong>&nbsp;of
                                                                                                                                the
                                                                                                                                processing
                                                                                                                                of
                                                                                                                                your
                                                                                                                                personal
                                                                                                                                data
                                                                                                                                if
                                                                                                                                it
                                                                                                                                is
                                                                                                                                used
                                                                                                                                for
                                                                                                                                targeted
                                                                                                                                advertising,
                                                                                                                                the
                                                                                                                                sale
                                                                                                                                of
                                                                                                                                personal
                                                                                                                                data,
                                                                                                                                or
                                                                                                                                profiling
                                                                                                                                in
                                                                                                                                furtherance
                                                                                                                                of
                                                                                                                                decisions
                                                                                                                                that
                                                                                                                                produce
                                                                                                                                legal
                                                                                                                                or
                                                                                                                                similarly
                                                                                                                                significant
                                                                                                                                effects
                                                                                                                                ("profiling")
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <div>
                                                                                                                            <strong><span
                                                                                                                                data-custom-class="heading_2">How to Exercise Your Rights</span></strong>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">To exercise these rights, you can contact us by submitting a&nbsp;</span><a
                                                                                                                            href="#"
                                                                                                                            rel="noopener noreferrer"
                                                                                                                            data-custom-class="link">data
                                                                                                                            subject
                                                                                                                            access
                                                                                                                            request</a><span
                                                                                                                            data-custom-class="body_text">,&nbsp;</span><span
                                                                                                                            data-custom-class="body_text">by emailing us at __________,&nbsp;</span><span
                                                                                                                            data-custom-class="body_text">or by referring to the contact details at the bottom of this document.</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">Under certain US state data protection laws, you can designate an&nbsp;authorized&nbsp;agent to make a request on your behalf. We may deny a request from an&nbsp;authorized&nbsp;agent that does not submit proof that they have been validly&nbsp;authorized&nbsp;to act on your behalf in accordance with applicable laws.</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <strong><span
                                                                                                                                data-custom-class="heading_2">Request Verification</span></strong>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">If you submit the request through an&nbsp;authorized&nbsp;agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <strong><span
                                                                                                                                data-custom-class="heading_1" id="policyupdates">11. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text"><em><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated&nbsp;"Revised"&nbsp;date at the top of this privacy notice. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <strong><span
                                                                                                                                data-custom-class="heading_1" id="contact">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">If you have questions or comments about this notice, you may&nbsp;contact us by post at:</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">__________</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">__________</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">__________</span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <strong><span
                                                                                                                                data-custom-class="heading_1" id="request">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please fill out and submit a&nbsp;</span><span
                                                                                                                            data-custom-class="body_text"><a
                                                                                                                            href="#"
                                                                                                                            rel="noopener noreferrer"
                                                                                                                            data-custom-class="link">data subject access request</a></span><span
                                                                                                                            data-custom-class="body_text">.</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;