import React from "react";
import FeatureCard from "./FeatureCard";
import ArticleCard from "./ArticleCard";
import {Global} from "../../helper/Global";
import {Helmet} from "react-helmet";

function Articles() {
    interface article {
        title: string;
        shortDescription: string;
        author: string;
        date: string;
        imageUrl: string;
        content: string;
        type: string;
    }

    interface authorProfile {
        username: string;
        displayName: string;
        profileImageUrl: string;
    }

    const [articles, setArticles] = React.useState([] as article[]);
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [authors, setAuthors] = React.useState([] as authorProfile[]);
    const [filteredArticles, setFilteredArticles] = React.useState([] as article[]);


    React.useEffect(() => {
        validateUser();
        fetchArticles();
        getAllAuthors();
    }, []);

    async function validateUser() {
        fetch(Global.BACKEND_URL + "/validate", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
        }).then(response => {
            if (response.status === 200) {
                setLoggedIn(true);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    async function getAllAuthors() {
        const response = await fetch(Global.BACKEND_URL + "/getAllProfiles", {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include"
        });
        const data = await response.json();
        setAuthors(data);
    }


    async function fetchArticles() {
        const response = await fetch(Global.BACKEND_URL + "/getArticles", {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include"
        });
        const data = await response.json();
        data.sort((a: article, b: article) => {
            // for some reason this is the only way to get dates to work on phones/safari maybe
            let date1 = a.date.split("-");
            let date2 = b.date.split("-");
            let newDate1 = new Date(parseInt(date1[2]), parseInt(date1[0]) - 1, parseInt(date1[1]));
            let newDate2 = new Date(parseInt(date2[2]), parseInt(date2[0]) - 1, parseInt(date2[1]));
            return newDate2.getTime() - newDate1.getTime();
        });
        setArticles(data);
    }

    return (
        <section className=" bg-[#282c34] pt-8">
            <Helmet>
                <title>Yangfecta - Articles</title>
                <meta name="description" content="List of articles written on Yangfecta" />
            </Helmet>
            {/* Featured Section */}
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                    <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-white">Featured
                        Articles</h2>
                    <p className="font-light sm:text-xl text-gray-400">Delivering Your Desired Insights Early and Often
                        Through Agile Blogging!</p>

                    {loggedIn ? (
                        <button
                            className="mt-5 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => {
                                window.location.href = "/createarticle"
                            }}
                        >
                            Create new post
                        </button>) : null}
                </div>
                <div className="grid gap-8 lg:grid-cols-2">
                    {articles.map((article, index) => (
                        // render first 2 articles as FeatureCard
                        index < 2 ? (
                            <FeatureCard
                                key={index}
                                title={article.title}
                                shortDescription={article.shortDescription}
                                authorUser={article.author}
                                author={authors.find((author) => author.username === article.author)?.displayName || article.author}
                                authorPfp={authors.find((author) => author.username === article.author)?.profileImageUrl || "https://media.istockphoto.com/id/1130884625/vector/user-member-vector-icon-for-ui-user-interface-or-profile-face-avatar-app-in-circle-design.jpg?s=612x612&w=0&k=20&c=1ky-gNHiS2iyLsUPQkxAtPBWH1BZt0PKBB1WBtxQJRE="}
                                date={article.date}
                                imageurl={article.imageUrl}
                                content={article.content}
                                type={article.type}
                            />
                        ) : null
                    ))}
                </div>
            </div>

            {/* horizontal line */}
            <div className="flex justify-center pb-20">
                <div className="w-3/4 h-px bg-gray-700"></div>
            </div>

            {/* Archive Section */}
            <div className="mx-auto max-w-screen-lg px-4 2xl:px-0">
                <div className="lg:flex lg:items-center lg:justify-between lg:gap-4">
                    <h2 className="shrink-0 text-xl font-semibold text-white sm:text-2xl">Latest
                        Articles</h2>

                    <form className="mt-4 w-full gap-4 sm:flex sm:items-center sm:justify-end lg:mt-0">
                        <label htmlFor="simple-search" className="sr-only">Search</label>
                        <div className="relative w-full flex-1 lg:max-w-sm">
                            <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                                <svg className="h-4 w-4 text-gray-400" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="text" id="simple-search"
                                   className="block w-full rounded-lg border px-4 py-2.5 ps-9 text-sm focus:border-primary-500 focus:ring-primary-500 border-gray-600 bg-gray-700 text-white placeholder:text-gray-400 focus:border-primary-500 focus:ring-primary-500"
                                   placeholder="Search Articles & Tutorials"
                                    onChange={(e) => {
                                        // filter articles after index 1 based on search input
                                        const search = e.target.value.toLowerCase();
                                        const filtered = articles.filter((article) => article.title.toLowerCase().includes(search) || article.shortDescription.toLowerCase().includes(search));
                                        if (search === "") {
                                            setFilteredArticles([]);
                                        } else {
                                        setFilteredArticles(filtered);
                                        }
                                    }}
                            />
                        </div>
                    </form>
                </div>

                <div className="mt-6 flow-root text-left">
                    <div className="-my-6 grid gap-4 pt-6">
                        {filteredArticles.length === 0 ? articles.map((article, index) => (
                            index > 1 ? (
                                <ArticleCard title={article.title} shortDescription={article.shortDescription}
                                             authorUser={article.author}
                                             author={authors.find((author) => author.username === article.author)?.displayName || article.author}
                                             date={article.date}
                                             imageurl={article.imageUrl}
                                             content={article.content} type={article.type}/>
                            ) : null )) : filteredArticles.map((article, index) => (
                            <ArticleCard title={article.title} shortDescription={article.shortDescription}
                                         authorUser={article.author}
                                         author={authors.find((author) => author.username === article.author)?.displayName || article.author}
                                         date={article.date}
                                         imageurl={article.imageUrl}
                                         content={article.content} type={article.type}/>
                        ))}

                    </div>
                </div>

                <div className="mt-6 flex items-center justify-center p-6">
                    <button type="button"
                            className="w-full rounded-lg px-5 py-2.5 text-sm font-medium hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 border-gray-600 bg-gray-800 text-gray-400 hover:bg-gray-700 hover:text-white focus:ring-gray-700 sm:w-auto">View
                        more articles
                    </button>
                </div>
            </div>

        </section>
    )
}

export default Articles;