import React, {useEffect} from "react";
import Cards from "./Cards";
import {Global} from "../../helper/Global";
import {Helmet} from "react-helmet";

function Authors() {
    interface Profile {
        username: string;
        displayName: string;
        bio: string;
        profileImageUrl: string;
    }

    const [authors, setAuthors] = React.useState([] as Profile[]);

    useEffect(() => {
        fetchAuthors();
    }, []);

    async function fetchAuthors() {
        const response = await fetch(Global.BACKEND_URL + "/getAllProfiles", {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        });
        const data = await response.json();
        setAuthors(data);
    }

    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 bg-[#282c34] text-white pt-10 pb-64">
            <Helmet>
                <title>Yangfecta - Authors</title>
                <meta name="description" content="Meet our team of authors" />
            </Helmet>

            <div className="space-y-2 pb-8 pt-6 md:space-y-5">
                <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
                    Authors
                </h1>
                <p className="text-lg leading-7 text-gray-400">
                    Meet our Team
                </p>
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-10 grid-cols-1">
                {authors.map((d) => (
                    <Cards
                        displayName={d.displayName}
                        username={d.username}
                        imageUrl={d.profileImageUrl}
                        bio={d.bio}
                    />
                ))}
            </div>
        </div>
    )
}

export default Authors;