import yanggopew from '../../assets/yanggopew.png';
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Global} from "../../helper/Global";
import {Helmet} from "react-helmet";

function Register() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [terms, setTerms] = useState(false);
    const [alert, setAlert] = useState("");
    let uniqueId = useParams().uniqueId;

    useEffect(() => {
        fetch(Global.BACKEND_URL + "/validateId", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({
                uniqueId: uniqueId
            })
        }).then((res) => {
            if (res.status !== 200) {
                window.location.href = "/Home";
            }
        }).catch((error) => {
            console.log(error)
        });
    }, []);

    function createAccount() {
        if (!username) {
            setAlert("Username cannot be empty");
            return;
        } else if (!password) {
            setAlert("Password cannot be empty");
            return;
        } else if (password !== confirmPassword) {
            setAlert("Passwords do not match");
            return;
        } else if (!terms) {
            setAlert("Please accept terms and conditions");
            return;
        } else {
            setAlert("");
        }
        fetch(Global.BACKEND_URL + "/signup", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({
                login: username,
                password: password,
                role: "ADMIN",
                signUpId: uniqueId
            })
        }).then((res) => {
            if (res.status === 200) {
                window.location.href = "/login";
            } else {
                throw new Error("Invalid login");
            }
        }).catch((error) => {
            console.log(error)

        })
    }

    return (
        <section className="bg-[#282c34]">
            <Helmet>
                <title>Yangfecta - Register</title>
                <meta name="description" content="Unique registration link to Yangfecta" />
            </Helmet>
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="mx-auto h-20 w-auto" src={yanggopew} alt="logo"/>
                </a>
                <div className="text-white text-xl font-semibold pb-5">This link is unique to you. Do not share this link. It will be deactivated upon registering.</div>
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Create an account
                        </h1>
                        <p className="text-red-600">{alert}</p>
                        <form className="space-y-4 md:space-y-6 text-left" action="#">
                            <div>
                                <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                                <input type="username" name="username" id="username" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#2563eb] focus:border-[#2563eb] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                       placeholder="username"
                                       onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#2563eb] focus:border-[#2563eb] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                       onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                                <input type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#2563eb] focus:border-[#2563eb] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                       onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-[#93c5fd] dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-[#2563eb] dark:ring-offset-gray-800"
                                           onClick={() => setTerms(!terms)}
                                           required/>
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">I accept the <a className="font-medium text-[#2563eb] hover:underline dark:text-[#3b82f6]" href="/tos" target="_blank">Terms and Conditions</a></label>
                                </div>
                            </div>
                            <button type="button" className="w-full text-white bg-[#2563eb] hover:bg-[#1d4ed8] focus:ring-4 focus:outline-none focus:ring-[#93c5fd] font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#2563eb] dark:hover:bg-[#1d4ed8] dark:focus:ring-[#1e40af]"
                                onClick={createAccount}>
                                Create an account</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register;
