import React, {useState} from "react";
import {BubbleMenu, useEditor, EditorContent} from "@tiptap/react";
import {StarterKit} from "@tiptap/starter-kit";
import Highlight from '@tiptap/extension-highlight'
import Typography from '@tiptap/extension-typography'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import {Global} from "../../helper/Global";
import {Helmet} from "react-helmet";
import {Youtube} from "@tiptap/extension-youtube";
import {TextAlign} from "@tiptap/extension-text-align";
import {Table} from "@tiptap/extension-table";
import {TableRow} from "@tiptap/extension-table-row";
import {TableHeader} from "@tiptap/extension-table-header";
import {TableCell} from "@tiptap/extension-table-cell";

const extensions = [
    StarterKit,
    Typography,
    Highlight,
    Image,
    Link,
    Youtube.configure({
        controls: true,
        nocookie: true,
    }),
    TextAlign.configure({
        types: ['heading', 'paragraph'],
    }),
    Table.configure({
        resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
]

function CreateArticle() {
    const content = sessionStorage.getItem("article") ? JSON.parse(sessionStorage.getItem("article") || "{}").content : '<h1>Ready to Start!</h1>'
    const [title, setTitle] = useState(sessionStorage.getItem("article") ? JSON.parse(sessionStorage.getItem("article") || "{}").title : "");
    const [shortDescription, setShortDescription] = useState(sessionStorage.getItem("article") ? JSON.parse(sessionStorage.getItem("article") || "{}").shortDescription : "");
    const [contentval, setContent] = useState(content);
    const [type, setType] = useState(sessionStorage.getItem("article") ? JSON.parse(sessionStorage.getItem("article") || "{}").type : "");
    const [imageUrl, setImageUrl] = useState(sessionStorage.getItem("article") ? JSON.parse(sessionStorage.getItem("article") || "{}").imageUrl : "");
    const [author, setAuthor] = useState(sessionStorage.getItem("article") ? JSON.parse(sessionStorage.getItem("article") || "{}").author : "");
    const editor = useEditor({
        extensions,
        content,
        onUpdate({editor}) {
            setContent(editor.getHTML());
        },
    });

    React.useEffect(() => {
        validateUser();
        getAuthor();
    }, []);

    async function validateUser() {
        fetch(Global.BACKEND_URL + "/validate", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
        }).then(response => {
            if (response.status !== 200) {
                window.location.href = "/login";
            }
        }).catch(error => {
            console.log(error);
        })
    }

    async function getAuthor() {
        fetch(Global.BACKEND_URL + "/profile", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
        }).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setAuthor(data.username);
                })
            }
        }).catch(error => {
            console.log(error);
        })
    }

    return (
        <section className="bg-[#282c34]">
            <Helmet>
                <title>Yangfecta - Create Article</title>
                <meta name="description" content="Write your article to post on Yangfecta"/>
            </Helmet>

            <div className="relative z-10 hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true"
                 id="markdown-modal">
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div
                            className="relative transform overflow-hidden rounded-lg bg-transparent text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-gray-700 px-4 pb-4 pt-5 sm:p-6 sm:pb-4 text-white">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <h3 className="text-base font-semibold leading-6" id="modal-title">Markdown
                                            Shortcuts</h3>
                                        <div className="mt-2">
                                            <p className="text-6xl font-black"># H1 Header</p>
                                            <p className="text-4xl font-black">## H2 Header</p>
                                            <p className="text-2xl font-bold">### H3 Header</p>
                                            <p className="text-1xl font-semibold">#### H4 Header</p>
                                            <p className="text-lg italic">*italic*</p>
                                            <p className="text-lg font-bold">**bold**</p>
                                            <p className="text-lg font-mono">`code`</p>
                                            <p className="text-lg font-mono">code block ctrl+shift+c</p>
                                            <p className="text-lg text-yellow-300">==highlighted==</p>
                                            <p className="text-lg line-through">~~strikethrough~~</p>
                                            <p className="text-lg">- bullet list</p>
                                            <p className="text-lg">1. numbered list</p>
                                            <p className="text-lg">&gt; " block quote</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-800 px-4 py-3 flex justify-center sm:px-6">
                                <button type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-[#1d4ed8] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1e40af] sm:ml-3 sm:w-auto"
                                        onClick={() => {
                                            let elemnt = document.getElementById('markdown-modal');
                                            if (elemnt != null) elemnt.className = ' hidden';
                                        }}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-8 px-4 mx-auto max-w-5xl lg:py-16">
                <h2 className="mb-4 text-xl font-bold text-white">Create A Post</h2>
                <form action="#">
                    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                        <div className="sm:col-span-2">
                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-white">Title *</label>
                            <input type="text" name="name" id="name"
                                   onChange={(e) => setTitle(e.target.value)}
                                   className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-[#3b82f6] focus:border-[#3b82f6]"
                                   placeholder="Enter title..."
                                   value={title}
                            />
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="shortDescription" className="block mb-2 text-sm font-medium text-white">Short
                                description *</label>
                            <input type="text" name="shortDescription" id="shortDescription"
                                   onChange={(e) => setShortDescription(e.target.value)}
                                   className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-[#3b82f6] focus:border-[#3b82f6]"
                                   placeholder="Enter short description..."
                                   value={shortDescription}
                            />
                        </div>
                        <div>
                            <label htmlFor="imgurl" className="block mb-2 text-sm font-medium text-white">Banner
                                image</label>
                            <input type="text" name="imgurl" id="imgurl"
                                   onChange={(e) => setImageUrl(e.target.value)}
                                   className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-[#3b82f6] focus:border-[#3b82f6]"
                                   placeholder="Enter img url..."
                                   value={imageUrl}
                            />
                        </div>
                        <div>
                            <label htmlFor="category" className="block mb-2 text-sm font-medium text-white">Type
                                *</label>
                            <select id="category"
                                    onChange={(e) => setType(e.target.value)}
                                    className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-[#3b82f6] focus:border-[#3b82f6]"
                                    value={type}
                            >
                                <option>Select Post Type</option>
                                <option value="Article">Article</option>
                                <option value="Tutorial">Tutorial</option>
                            </select>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="description"
                                   className="block mb-2 text-sm font-medium text-white">Content</label>
                            <div
                                className="prose prose-lg max-w-sm sm:max-w-full text-left block p-2.5 w-full h-full rounded-lg border bg-gray-700 border-gray-600 text-white prose-headings:text-white prose-strong:text-white prose-li:marker:text-white prose-ol:marker:text-white prose-a:text-indigo-400 prose-a:no-underline">
                                <EditorContent editor={editor}/>
                            </div>
                            {editor && <BubbleMenu editor={editor} tippyOptions={{duration: 100}}>
                                <div className="grid grid-cols-6 gap-1">
                                    <button
                                        type="button"
                                        onClick={() => editor.chain().focus().toggleHeading({level: 1}).run()}
                                        className={editor.isActive('heading', {level: 1}) ?
                                            'bg-gray-900 rounded-lg px-2' :
                                            'bg-gray-800 rounded-lg px-2'}
                                    >
                                        H1
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => editor.chain().focus().toggleHeading({level: 2}).run()}
                                        className={editor.isActive('heading', {level: 2}) ?
                                            'bg-gray-900 rounded-lg px-2' :
                                            'bg-gray-800 rounded-lg px-2'}
                                    >
                                        H2
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => editor.chain().focus().toggleHeading({level: 3}).run()}
                                        className={editor.isActive('heading', {level: 3}) ?
                                            'bg-gray-900 rounded-lg px-2' :
                                            'bg-gray-800 rounded-lg px-2'}
                                    >
                                        H3
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => editor.chain().focus().toggleBold().run()}
                                        className={editor.isActive('bold') ?
                                            'bg-gray-900 rounded-lg px-2' :
                                            'bg-gray-800 rounded-lg px-2'}
                                    >
                                        Bold
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => editor.chain().focus().toggleItalic().run()}
                                        className={editor.isActive('italic') ?
                                            'bg-gray-900 rounded-lg' :
                                            'bg-gray-800 rounded-lg'}
                                    >
                                        Italic
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => editor.chain().focus().toggleStrike().run()}
                                        className={editor.isActive('strike') ?
                                            'bg-gray-900 rounded-lg' :
                                            'bg-gray-800 rounded-lg'}
                                    >
                                        Strike
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                                        className={editor.isActive('bulletList') ?
                                            'bg-gray-900 rounded-lg px-2 col-span-2' :
                                            'bg-gray-800 rounded-lg px-2 col-span-2'}
                                    >
                                        Bullet list
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                                        className={editor.isActive('orderedList') ?
                                            'bg-gray-900 rounded-lg col-span-2' :
                                            'bg-gray-800 rounded-lg col-span-2'}
                                    >
                                        Ordered list
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => editor.chain().focus().toggleBlockquote().run()}
                                        className={editor.isActive('blockquote') ?
                                            'bg-gray-900 rounded-lg col-span-2' :
                                            'bg-gray-800 rounded-lg col-span-2'}
                                    >
                                        Blockquote
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                                        className={editor.isActive('codeBlock') ?
                                            'bg-gray-900 rounded-lg col-span-3' :
                                            'bg-gray-800 rounded-lg col-span-3'}
                                    >
                                        Codeblock
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => editor.chain().focus().setHorizontalRule().run()}
                                        className='bg-gray-800 rounded-lg col-span-3'
                                    >
                                        Horizontal Rule
                                    </button>
                                </div>
                            </BubbleMenu>}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 mt-12 mb-36">
                        <button type="button"
                                className="py-2.5 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg"
                                onClick={() => {
                                    if (title === "") {
                                        alert("Title cannot be empty");
                                        return;
                                    } else if (shortDescription === "") {
                                        alert("Description cannot be empty");
                                        return;
                                    } else if (type !== "Article" && type !== "Tutorial") {
                                        alert("Please select a post type");
                                        return;
                                    } else if (contentval === "") {
                                        alert("Content cannot be empty");
                                        return;
                                    }
                                    let newDate = new Date();
                                    let date = (newDate.getMonth() + 1) + "-" + newDate.getDate() + "-" + newDate.getFullYear();
                                    let article = {
                                        title: title,
                                        author: author,
                                        date: date,
                                        shortDescription: shortDescription,
                                        imageUrl: imageUrl ? imageUrl : "",
                                        content: contentval,
                                        type: type,
                                    }
                                    sessionStorage.setItem("article", JSON.stringify(article));
                                    window.location.href = "/preview";
                                }}
                        >
                            Preview & Post
                        </button>
                        <button
                            className="py-2.5 text-white bg-gray-700 rounded-lg hover:bg-gray-800 px-5 text-sm font-medium"
                            type="button"
                            onClick={() => {
                                let elemnt = document.getElementById('markdown-modal');
                                if (elemnt != null) elemnt.className = 'relative z-10';
                            }}
                        >Markdown Cheatsheet
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default CreateArticle;