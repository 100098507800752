import logo from "../../assets/yanggopew.png";
import React, {useEffect} from "react";
import yanggopew from "../../assets/yanggopew.png";
import yanggoweee from "../../assets/yanggoweee.png";
import background from "../../assets/background.jpg";

function Home() {
    return (
        <div>
            <header className="App-header bg-cover bg-fixed" style={{backgroundImage: `url(${background})`}}>
                <section className="bg-[#282c34] bg-opacity-95 rounded-xl pt-10 mx-5">
                    <div className="max-w-screen-xl text-center grid grid-cols-1">
                        <img src={logo} className="mx-auto h-28 App-logo" alt="logo"/>
                        <img src={yanggoweee} alt="logo" className="mx-auto h-20"/>
                        <p className="mb-8 text-lg font-normal lg:text-xl sm:px-16 xl:px-40 text-gray-400 mx-5 sm:mx-0">Your premier
                            destination for exploring the forefront of innovation and technology. Through our dedication
                            to excellence, we illuminate the path forward with revolutionary solutions, insightful
                            perspectives, and a commitment to shaping tomorrow's digital landscape.</p>
                        <div
                            className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4 mx-10 sm:mx-0">
                            <a href="#about"
                               className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-indigo-600 hover:bg-primary-800 hover:bg-indigo-500">
                                Learn more
                                <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                          clip-rule="evenodd"></path>
                                </svg>
                            </a>
                            <a href="#subscribe"
                               className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center rounded-lg border focus:ring-4 text-white border-gray-700 hover:bg-gray-700 focus:ring-gray-800">
                                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                </svg>
                                Subscribe
                            </a>
                        </div>
                    </div>
                </section>
            </header>
            <section className="bg-gray-800" id="subscribe">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-md sm:text-center">
                        <h2 className="mb-4 text-3xl tracking-tight font-extrabold sm:text-4xl text-white">Sign up for
                            our newsletter</h2>
                        <p className="mx-auto mb-8 max-w-2xl font-light md:mb-12 sm:text-xl text-gray-400">Stay up to
                            date with the latest news, articles, projects, and more from yangfecta.</p>
                        <form action="#">
                            <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
                                <div className="relative w-full">
                                    <label htmlFor="email" className="hidden mb-2 text-sm font-medium text-gray-300">Email
                                        address</label>
                                    <div
                                        className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        <svg className="w-5 h-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                        </svg>
                                    </div>
                                    <input
                                        className="block p-3 pl-10 w-full text-sm rounded-lg border sm:rounded-none sm:rounded-l-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-[#3b82f6] focus:border-[#3b82f6]"
                                        placeholder="Enter your email" type="email" id="email"/>
                                </div>
                                <div>
                                    <button type="submit"
                                            className="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer border-[#2563eb] sm:rounded-none sm:rounded-r-lg focus:ring-4 bg-[#2563eb] hover:bg-[#3b82f6] focus:ring-[#3b82f6]">Subscribe
                                    </button>
                                </div>
                            </div>
                            <div
                                className="mx-auto max-w-screen-sm text-sm text-left newsletter-form-footer text-gray-300">We
                                care about the protection of your data. <a href="/privacypolicy"
                                                                           className="font-medium text-[#3b82f6] hover:underline">Read
                                    our Privacy Policy</a>.
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section className="overflow-hidden bg-[#282c34] py-20 sm:py-32 text-left text-gray-400" id="about">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div
                        className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <h2 className="text-base font-semibold leading-7 text-indigo-600">#1 in Innovation</h2>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">What is
                                    yangfecta</p>
                                <p className="mt-6 text-lg leading-8">Lorem ipsum, dolor sit amet consectetur
                                    adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate
                                    blanditiis ratione.</p>
                                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                    <div className="relative pl-9">
                                        <dt className="inline font-semibold text-white">
                                            <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                                                 fill="currentColor" viewBox="0 0 24 24"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                   stroke-linejoin="round"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <path
                                                        d="M.221 16.268a15.064 15.064 0 0 0 1.789 1.9C2.008 18.111 2 18.057 2 18a5.029 5.029 0 0 1 3.233-4.678 1 1 0 0 0 .175-1.784A2.968 2.968 0 0 1 4 9a2.988 2.988 0 0 1 5.022-2.2 5.951 5.951 0 0 1 2.022-.715 4.994 4.994 0 1 0-7.913 6.085 7.07 7.07 0 0 0-2.91 4.098zM23.779 16.268a7.07 7.07 0 0 0-2.91-4.1 4.994 4.994 0 1 0-7.913-6.086 5.949 5.949 0 0 1 2.022.715 2.993 2.993 0 1 1 3.614 4.74 1 1 0 0 0 .175 1.784A5.029 5.029 0 0 1 22 18c0 .057-.008.111-.01.167a15.065 15.065 0 0 0 1.789-1.899z"></path>
                                                    <path
                                                        d="M18.954 20.284a7.051 7.051 0 0 0-3.085-5.114A4.956 4.956 0 0 0 17 12a5 5 0 1 0-8.869 3.17 7.051 7.051 0 0 0-3.085 5.114 14.923 14.923 0 0 0 1.968.849C7.012 21.088 7 21.046 7 21a5.031 5.031 0 0 1 3.233-4.678 1 1 0 0 0 .175-1.785A2.964 2.964 0 0 1 9 12a3 3 0 1 1 6 0 2.964 2.964 0 0 1-1.408 2.537 1 1 0 0 0 .175 1.785A5.031 5.031 0 0 1 17 21c0 .046-.012.088-.013.133a14.919 14.919 0 0 0 1.967-.849z"></path>
                                                </g>
                                            </svg>
                                            Who we are
                                        </dt>
                                        <dd className="inline text-gray-400"> Something about ASU students.. Lorem
                                            ipsum, dolor sit amet consectetur
                                            adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor
                                            cupiditate blanditiis ratione.
                                        </dd>
                                    </div>
                                    <div className="relative pl-9">
                                        <dt className="inline font-semibold text-white">
                                            <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                                                 fill="currentColor" version="1.1" id="Capa_1"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 442 442"
                                                 xmlSpace="preserve">
                                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                   stroke-linejoin="round"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <g>
                                                        <path
                                                            d="M171,336H70c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.523,0,10-4.477,10-10S176.523,336,171,336z"></path>
                                                        <path
                                                            d="M322,336H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,336,322,336z"></path>
                                                        <path
                                                            d="M322,86H70c-5.523,0-10,4.477-10,10s4.477,10,10,10h252c5.522,0,10-4.477,10-10S327.522,86,322,86z"></path>
                                                        <path
                                                            d="M322,136H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,136,322,136z"></path>
                                                        <path
                                                            d="M322,186H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,186,322,186z"></path>
                                                        <path
                                                            d="M322,236H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,236,322,236z"></path>
                                                        <path
                                                            d="M322,286H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,286,322,286z"></path>
                                                        <path
                                                            d="M171,286H70c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.523,0,10-4.477,10-10S176.523,286,171,286z"></path>
                                                        <path
                                                            d="M171,136H70c-5.523,0-10,4.477-10,10v101c0,5.523,4.477,10,10,10h101c5.523,0,10-4.477,10-10V146 C181,140.477,176.523,136,171,136z M161,237H80v-81h81V237z"></path>
                                                        <path
                                                            d="M422,76h-30V46c0-11.028-8.972-20-20-20H20C8.972,26,0,34.972,0,46v320c0,27.57,22.43,50,50,50h342c27.57,0,50-22.43,50-50 V96C442,84.972,433.028,76,422,76z M422,366c0,16.542-13.458,30-30,30H50c-16.542,0-30-13.458-30-30V46h352v305 c0,13.785,11.215,25,25,25c5.522,0,10-4.477,10-10s-4.478-10-10-10c-2.757,0-5-2.243-5-5V96h30V366z"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                            Articles
                                        </dt>
                                        <dd className="inline text-gray-400"> Something about the innovation of our
                                            articles.. Anim aute id magna aliqua ad ad non
                                            deserunt sunt. Qui irure qui lorem cupidatat commodo.
                                        </dd>
                                    </div>
                                    <div className="relative pl-9">
                                        <dt className="inline font-semibold text-white">
                                            <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                                 fill="currentColor">
                                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                   stroke-linejoin="round"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <g>
                                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                                        <path
                                                            d="M13 21v2.5l-3-2-3 2V21h-.5A3.5 3.5 0 0 1 3 17.5V5a3 3 0 0 1 3-3h14a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1h-7zm-6-2v-2h6v2h6v-3H6.5a1.5 1.5 0 0 0 0 3H7zM7 5v2h2V5H7zm0 3v2h2V8H7zm0 3v2h2v-2H7z"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                            Projects
                                        </dt>
                                        <dd className="inline text-gray-400"> Talk about the innovative ideas in our
                                            projects. Ac tincidunt sapien vehicula erat auctor
                                            pellentesque rhoncus. Et magna sit morbi lobortis.
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <img src={yanggopew} alt="yang"
                             className="hidden lg:block max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[35rem] md:-ml-4 lg:-ml-0"/>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home;