import React, {useEffect} from "react";
import {Global} from "../../helper/Global";
import {Helmet} from "react-helmet";

function Settings() {
    interface Profile {
        username: string;
        displayName: string;
        bio: string;
        profileImageUrl: string;
    }

    const [profile, setProfile] = React.useState({} as Profile);
    const [profileImage, setProfileImage] = React.useState("");
    const [displayName, setDisplayName] = React.useState("");
    const [bio, setBio] = React.useState("");
    const [textAlert, setAlert] = React.useState("");

    useEffect(() => {
        getProfile();
    }, []);

    async function getProfile() {
        fetch(Global.BACKEND_URL + "/profile", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
        }).then(response => {
            if (response.status !== 200) {
                window.location.href = "/Login";
            }
            response.json().then(data => {
                setProfile(data);
                setProfileImage(data.profileImageUrl)
                setDisplayName(data.displayName);
                setBio(data.bio);
            });
        }).catch(error => {
            window.location.href = "/Login";
            console.log(error);
        });
    }

    function updateProfile() {
        fetch(Global.BACKEND_URL + "/updateProfile", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
            body: JSON.stringify({
                username: profile.username,
                displayName: displayName,
                bio: bio,
                profileImageUrl: profileImage
            }),
        }).then(response => {
            window.location.reload();
            alert("Profile updated successfully");
            setAlert("Profile updated successfully");
        }).catch(error => {
            console.log(error);
            setAlert("Failed to update profile");
        });
    }

    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 bg-[#282c34] text-white pt-24 md:pt-32 pb-64">
            <Helmet>
                <title>Yangfecta - Settings</title>
                <meta name="description" content="Settings page for your profile" />
            </Helmet>
            <h2 className="pl-6 text-2xl font-bold sm:text-xl">Profile Settings</h2>

            <div className="grid max-w-2xl mx-auto mt-8">
                <div className="flex flex-col items-center space-y-5 sm:flex-row sm:space-y-0">

                    <img className="object-cover w-40 h-40 p-1 rounded-full ring-2 ring-indigo-300 dark:ring-indigo-500"
                         src={profileImage ? profileImage : "https://media.istockphoto.com/id/1130884625/vector/user-member-vector-icon-for-ui-user-interface-or-profile-face-avatar-app-in-circle-design.jpg?s=612x612&w=0&k=20&c=1ky-gNHiS2iyLsUPQkxAtPBWH1BZt0PKBB1WBtxQJRE="}
                         alt="avatar"/>

                    <div className="flex flex-col space-y-5 sm:ml-12">
                        <button type="button"
                                className="py-3.5 px-7 text-base font-medium text-white focus:outline-none bg-indigo-600 rounded-lg hover:bg-indigo-500"
                                onClick={() => {
                                    let newpfp = prompt("Enter image URL", profile.profileImageUrl);
                                    setProfileImage(newpfp ? newpfp : profileImage);
                                }}
                        >
                            Change picture
                        </button>
                        <button type="button"
                                className="py-3.5 px-7 text-base font-medium text-indigo-600 focus:outline-none bg-white rounded-lg hover:bg-indigo-100 hover:text-[#202142]"
                                onClick={() => {
                                    setProfileImage("https://media.istockphoto.com/id/1130884625/vector/user-member-vector-icon-for-ui-user-interface-or-profile-face-avatar-app-in-circle-design.jpg?s=612x612&w=0&k=20&c=1ky-gNHiS2iyLsUPQkxAtPBWH1BZt0PKBB1WBtxQJRE=");
                                }}
                        >
                            Delete picture
                        </button>
                    </div>
                </div>

                <div className="items-center mt-8 sm:mt-14">

                    <div
                        className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                        <div className="w-full">
                            <label htmlFor="first_name"
                                   className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Username</label>
                            <input type="text" id="first_name"
                                   className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-700 placeholder-gray-400 text-white focus:ring-[#3b82f6] focus:border-[#3b82f6]"
                                   placeholder="Your first name" value={profile.username} disabled={true}/>
                        </div>

                        <div className="w-full">
                            <label htmlFor="last_name"
                                   className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Display
                                name</label>
                            <input type="text" id="last_name"
                                   className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-[#3b82f6] focus:border-[#3b82f6]"
                                   placeholder="Your last name" value={displayName}
                                   onChange={(e) => {
                                       setDisplayName(e.target.value);
                                   }}
                            />
                        </div>

                    </div>

                    <div className="mb-2 sm:mb-6">
                        <label htmlFor="bio"
                               className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Bio</label>
                        <input type="bio" id="bio"
                               className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-[#3b82f6] focus:border-[#3b82f6]"
                               placeholder="Write your bio here..." value={bio}
                               onChange={(e) => {
                                   setBio(e.target.value);
                               }}
                        />
                    </div>

                    <div className="flex flex-wrap justify-center">
                        <button type="button"
                                className="text-white bg-indigo-700  hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800"
                                onClick={updateProfile}>
                            Save
                        </button>
                        <p className="text-green-500 py-2.5 pl-3">{textAlert}</p>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Settings;