function Cookie() {
    let cookie;
    if (localStorage.getItem('cookie') != null) {
        return null;
    }
    return (
        <section
            className="fixed max-w-md p-4 mx-auto border bg-gray-800 left-0 md:left-6 md:bottom-8 lg:left-12 bottom-0 lg:bottom-16 border-gray-700 rounded-2xl"
            id="cookieNotice">
            <h2 className="font-semibold text-white">🍪 Cookie Notice</h2>

            <p className="mt-4 text-sm text-gray-300">We use cookies on our website. By
                continuing to use our site, you consent to the use of cookies as described in our <a href="/cookiepolicy"
                                                                                                     className="text-blue-500 hover:underline">Cookies
                    policies</a>. </p>

            <div className="items-center justify-between mt-4 gap-x-4 shrink-0">
                <button
                    className=" text-xs bg-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
                    onClick={() => {
                        localStorage.setItem('cookie', 'true');
                        cookie = document.getElementById('cookieNotice');
                        if (cookie != null) cookie.className += ' hidden';
                    }}>
                    Accept
                </button>
            </div>
        </section>
    )
}

export default Cookie;