import {Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {Link} from "react-router-dom";
import yanggopew from "../../assets/yanggopew.png";
import LoggedContext from "../../helper/LoggedContext";
import React from "react";
import {Global} from "../../helper/Global";

const navigation = [
    {name: 'Home', href: '/Home', current: false},
    {name: 'Articles', href: '/Articles', current: false},
    {name: 'Projects', href: '/Projects', current: false},
    {name: 'Authors', href: '/Authors', current: false},
]

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

function logout() {
    fetch(Global.BACKEND_URL + "/signout", {
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        credentials: "include"
    }).then(() => {
        window.location.reload();
    })
}

function Navbar() {
    interface profile {
        username: string;
        displayName: string;
        bio: string;
        profileImageUrl: string;
    }

    const [logged, setLogged] = React.useState(false);
    const [profile, setProfile] = React.useState({} as profile);

    React.useEffect(() => {
        validateUser();
    }, []);

    async function validateUser() {
        fetch(Global.BACKEND_URL + "/profile", {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            credentials: "include"
        }).then(response => {
            if (response.status === 200) {
                setLogged(true);
                response.json().then(data => {
                    setProfile(data);
                })
            } else {
                setLogged(false);
            }
        }).catch(() => {
            setLogged(false);
        });
    }

    return (
        <Disclosure as="nav" className="bg-gray-900 w-full">
            {({open}) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <DisclosureButton
                                    className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5"/>
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                    )}
                                </DisclosureButton>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <img className="h-10 w-auto" src={yanggopew} alt="Yangfecta"/>
                                </div>
                                <div className="hidden sm:ml-3 sm:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                className={classNames(
                                                    item.current ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'rounded-md px-3 py-2 text-sm font-medium',
                                                )}
                                                id={item.name}
                                                aria-current={item.current ? 'page' : undefined}
                                                // on click change classnames if item.current is true
                                                onClick={() => {
                                                    for (let i = 0; i < navigation.length; i++) {
                                                        if (navigation[i].name === item.name) {
                                                            navigation[i].current = true;
                                                            document.getElementById(navigation[i].name)?.classList.add('bg-gray-800', 'text-white');
                                                        } else {
                                                            navigation[i].current = false;
                                                            document.getElementById(navigation[i].name)?.classList.remove('bg-gray-800', 'text-white');
                                                        }
                                                    }
                                                }}
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {logged ? (
                                <div
                                    className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                    <button
                                        type="button"
                                        className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                    >
                                        <span className="absolute -inset-1.5"/>
                                        <span className="sr-only">View notifications</span>
                                        <BellIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="relative ml-3">
                                        <div>
                                            <MenuButton
                                                className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                <span className="absolute -inset-1.5"/>
                                                <span className="sr-only">Open user menu</span>
                                                <img
                                                    className="h-8 w-8 rounded-full"
                                                    src={profile.profileImageUrl !== null ? profile.profileImageUrl : "https://media.istockphoto.com/id/1130884625/vector/user-member-vector-icon-for-ui-user-interface-or-profile-face-avatar-app-in-circle-design.jpg?s=612x612&w=0&k=20&c=1ky-gNHiS2iyLsUPQkxAtPBWH1BZt0PKBB1WBtxQJRE="}
                                                    alt=""
                                                />
                                            </MenuButton>
                                        </div>
                                        <MenuItems
                                            transition
                                            className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                        >
                                            <MenuItem>
                                                {({focus}) => (
                                                    <a
                                                        className={classNames(focus ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                        onClick={() => {
                                                            window.location.href = "/profile/" + profile.username;
                                                        }}
                                                    >
                                                        Your Profile
                                                    </a>
                                                )}
                                            </MenuItem>
                                            <MenuItem>
                                                {({focus}) => (
                                                    <a
                                                        className={classNames(focus ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                        onClick={() => {
                                                            window.location.href = "/settings";
                                                        }}
                                                    >
                                                        Settings
                                                    </a>
                                                )}
                                            </MenuItem>
                                            <MenuItem>
                                                {({focus}) => (
                                                    <a
                                                        className={classNames(focus ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                        onClick={logout}
                                                    >
                                                        Sign out
                                                    </a>
                                                )}
                                            </MenuItem>
                                        </MenuItems>
                                    </Menu>
                                </div>
                            ) : (
                                <div
                                    className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                    <Link
                                        className="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                                        to="Login">Log in</Link>
                                </div>
                            )}
                        </div>
                    </div>

                    <DisclosurePanel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {navigation.map((item) => (
                                <DisclosureButton
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={classNames(
                                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium',
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </DisclosureButton>
                            ))}
                        </div>
                    </DisclosurePanel>
                </>
            )}
        </Disclosure>
    )
}

export default Navbar;